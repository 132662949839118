@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-ellipse-coloring {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc1MCIgaGVpZ2h0PSIxNzUwIiB2aWV3Qm94PSIwIDAgMTc1MCAxNzUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjYiIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2ZfMTFfMzQ2NzEpIj4KPGNpcmNsZSBjeD0iODc1LjEwMyIgY3k9Ijg3NS4xMDMiIHI9IjQ3NC44MzQiIHRyYW5zZm9ybT0icm90YXRlKDQ3LjAwOTUgODc1LjEwMyA4NzUuMTAzKSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzExXzM0NjcxKSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2ZfMTFfMzQ2NzEiIHg9IjAuMjY3NzMxIiB5PSIwLjI2NzU3OCIgd2lkdGg9IjE3NDkuNjciIGhlaWdodD0iMTc0OS42NyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJzaGFwZSIvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyMDAiIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xMV8zNDY3MSIvPgo8L2ZpbHRlcj4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzExXzM0NjcxIiB4MT0iNDg4Ljg5NiIgeTE9IjEyNjkuNjEiIHgyPSIxMzI2LjA1IiB5Mj0iNTAwLjI0OCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjOUUwMEQxIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzRFMzFGRiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=);
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}
.bg-ellipse-coloring-text {
  background-image: url('data:image/svg+xml,<svg width="1170" height="1171" viewBox="0 0 1170 1171" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.35" filter="url(%23filter0_f_106_1161)"><circle cx="385" cy="385" r="385" transform="matrix(-4.37114e-08 1 1 4.37114e-08 200 200.507)" fill="url(%23paint0_linear_106_1161)"/></g><defs><filter id="filter0_f_106_1161" x="0" y="0.506714" width="1170" height="1170" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_106_1161"/></filter><linearGradient id="paint0_linear_106_1161" x1="71.859" y1="704.872" x2="750.634" y2="81.0634" gradientUnits="userSpaceOnUse"><stop stop-color="%239E00D1"/><stop offset="1" stop-color="%234E31FF"/></linearGradient></defs></svg>');
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

.bg-ellipse-pink-coloring {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%221041%22%20height%3D%221020%22%20viewBox%3D%220%200%201041%201020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20opacity%3D%220.15%22%20filter%3D%22url(%23filter0_f_106_6009)%22%3E%3Cellipse%20cx%3D%22320.5%22%20cy%3D%22310%22%20rx%3D%22320.5%22%20ry%3D%22310%22%20transform%3D%22matrix(1%200%200%20-1%20200%20820)%22%20fill%3D%22url(%23paint0_linear_106_6009)%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3Cfilter%20id%3D%22filter0_f_106_6009%22%20x%3D%220%22%20y%3D%220%22%20width%3D%221041%22%20height%3D%221020%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22%2F%3E%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22shape%22%2F%3E%3CfeGaussianBlur%20stdDeviation%3D%22100%22%20result%3D%22effect1_foregroundBlur_106_6009%22%2F%3E%3C%2Ffilter%3E%3ClinearGradient%20id%3D%22paint0_linear_106_6009%22%20x1%3D%222.38791e-06%22%20y1%3D%22310%22%20x2%3D%22641%22%20y2%3D%22310%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%3Cstop%20stop-color%3D%22%23714BDB%22%2F%3E%3Cstop%20offset%3D%220.38%22%20stop-color%3D%22%238553EC%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23AF48E9%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  src: url('/public/fonts/Outfit-VariableFont_wght.ttf') format('truetype');
}
